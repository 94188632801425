import * as FS from "@fullstory/browser";
import { NoAttribution } from "@hex/common";
import React from "react";

import { HexMenuDivider, HexMenuItem } from "../../hex-components";
import { useOrgsPickerClientQuery } from "../../orgs.generated";
import { CurrentUser } from "../../redux/slices/currentUserSlice.js";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { isSelfServeEnabled, isSingleTenant } from "../../util/data";
import { LoadingView } from "../../views/LoadingView";
import { NavWorkspacePicker } from "../common/NavWorkspacePicker";
import { AddIcon, ArrowRightIcon, LogOutIcon } from "../icons/CustomIcons";

interface AuthMenuItemsProps {
  readonly currentOrg?: CurrentUser["org"];
  isPlatformAdmin?: boolean;
  isInTopNav?: boolean;
}

export const AuthMenuItems: React.FunctionComponent<AuthMenuItemsProps> = ({
  currentOrg,
  isInTopNav = false,
}) => {
  const { data } = useOrgsPickerClientQuery();

  // useOrgsPickerClientQuery is a custom written GQL query that
  // doesn't use loading or error
  if (!data?.orgPickerClient.loaded) {
    return <LoadingView />;
  }

  const logOutSingleOrg =
    data && data.orgPickerClient.authenticatedUsers.length > 1;

  return (
    <>
      <NavWorkspacePicker
        authedUsers={data.orgPickerClient.authenticatedUsers}
        isInTopNav={isInTopNav}
        unauthUsers={data.orgPickerClient.unauthenticatedUsers}
      />
      {!isSingleTenant && isSelfServeEnabled && (
        <HexMenuItem
          key="Add"
          href={
            isSelfServeEnabled
              ? Routes.SIGNUP.getUrl({ attribution: NoAttribution })
              : Routes.LOGIN.getUrl({ attribution: NoAttribution })
          }
          icon={<AddIcon />}
          text="Add a new workspace"
        />
      )}
      <HexMenuDivider />
      <HexMenuItem
        key="LogoutSingle"
        data-cy={CyData.LOGOUT}
        href={Routes.LOGOUT.getUrl({ orgId: currentOrg?.id })}
        icon={<ArrowRightIcon />}
        text={
          logOutSingleOrg && currentOrg != null
            ? `Log out of ${currentOrg.displayName}`
            : "Log out"
        }
      />
      {logOutSingleOrg && (
        <HexMenuItem
          key="LogoutAll"
          href={Routes.LOGOUT.getUrl({})}
          icon={<LogOutIcon />}
          text="Log out of all workspaces"
          onClick={FS.isInitialized() ? FS.anonymize : undefined}
        />
      )}
    </>
  );
};
